import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { registerApi } from '../../api/register';
import Preloader from '../../components/Preloader/Preloader';
import { random, sample } from 'lodash';
const DEFAULT_CAMPAIGN = '17';
const replaceUsernames = ['_online', '_kiss', '_flirt', '_fun', '_date'];

const RegistrationPopup = ({ isOpen, handleClose }) => {
  const [loading, setLoading] = useState(false);
  let [searchParams] = useSearchParams();
  const [popupInfo, setPopupInfo] = useState({
    page: 1,
    headerText: 'Glückwunsch!',
  });
  const [formData, setFormData] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const emailValue = watch('email');
  const countryValue = watch('country', 'DE');
  const zipValue = watch('zip');

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = 'auto';
    }
    setPopupInfo({
      page: 1,
      headerText: 'Glückwunsch!',
    });
  }, [isOpen]);

  const emailCodes = [
    'gmail.com',
    'web.de',
    'gmx.de',
    't-online.de',
    'yahoo.com',
    'yahoo.de',
    'gmx.net',
    'aol.com',
    'hotmail.com',
    'hotmail.de',
  ];

  const isOver18 = (day, month, year) => {
    let birthdate = moment([year, month - 1, day]);
    let today = moment();
    let age = today.diff(birthdate, 'years');
    return age >= 18;
  };

  const onAgeFormSubmit = (data) => {
    const isAdult = isOver18(data.day, data.month, data.year);
    if (!isAdult) {
      setError('isOver18', { type: 'custom' });
    } else {
      clearErrors('isOver18');
      const date = moment({ year: data.year, month: data.month - 1, day: data.day }).format(
        'YYYY-MM-DD',
      );
      setFormData((prev) => {
        return {
          ...prev,
          dob: date,
        };
      });
      setPopupInfo({
        page: 3,
        headerText: 'Dein Standort',
      });
    }
  };

  const onLandSubmit = (data) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setFormData((prev) => {
        return {
          ...prev,
          country: data.county,
          zip: data.zip,
        };
      });
      setPopupInfo({
        page: 4,
        headerText: 'Suche',
      });
    }, 4 * 1000);
  };

  const onFinalFormSubmit = async (data) => {
    setLoading(true);
    let campaign = searchParams.get('campaign');
    if (!campaign) {
      campaign = window.localStorage.getItem('campaign');
    }
    if (!campaign) {
      campaign = DEFAULT_CAMPAIGN;
    }

    let username = data.username;
    if (username.includes('@')) {
      let lastIndexOfDog = username.lastIndexOf('@');
      username = username.slice(0, lastIndexOfDog);
      username = `${username}${sample(replaceUsernames)}`;
    }

    const body = {
      username: username,
      password: data.password,
      email: data.email,
      dob: formData.dob,
      gender: 'MALE',
      searching: 'FEMALE',
      county: data.country,
      zip: data.zip,
      campaign: campaign,
    };

    const result = await registerApi(body);
    setLoading(false);
  };

  const renderStep = () => {
    return <div className="text-center text-sm primary-color">Schritt {popupInfo.page} von 5</div>;
  };

  const renderFirstPage = () => {
    const currentProfileLS = window.localStorage.getItem('current-profile');
    let contentInfoHTML = `
      <div className="mb-3">Du hast einen VIP Zugang gewonnen!</div>
      Wähle deine Vorlieben aus und registriere dich KOSTENLOS!
    `;
    if (currentProfileLS) {
      const currentProfile = JSON.parse(currentProfileLS);
      contentInfoHTML = `
        <div className="mb-3">Du hast einen VIP Zugang gewonnen!</div>
        Damit kannst du jetzt diskret und KOSTENLOS Kontakt mit ${currentProfile.name} (${currentProfile.age}) aufnehmen!
      `;
    }

    return (
      <>
        <div
          dangerouslySetInnerHTML={{ __html: contentInfoHTML }}
          className="font-bold text-lg text-center primary-color"></div>
        <div className="flex justify-center w-full">
          <button
            onClick={() => {
              setPopupInfo({
                page: 2,
                headerText: 'Dein Alter',
              });
            }}
            className="next-button p-2 px-4 rounded cursor-pointer text-xl">
            Los geht's!
          </button>
        </div>
        {renderStep()}
      </>
    );
  };

  const renderSecondPage = () => {
    return (
      <div className="flex flex-col gap-3">
        <div className="font-bold text-center primary-color">Wie alt bist du?</div>
        <form
          onSubmit={handleSubmit(onAgeFormSubmit)}
          className="flex flex-col justify-center align-center gap-3 mb-2">
          <div className="flex items-center justify-between gap-4 mx-auto">
            <input
              {...register('day', { required: true, min: 1, max: 31 })}
              placeholder="Tag"
              type="number"
              onChange={(e) => {
                const value = +e.target.value;
                if (value == 0) {
                  setValue('day', '', { shouldValidate: true });
                  return;
                }
                if (value > 31) {
                  let newValue = value.toString().slice(0, -1);
                  setValue('day', +newValue, { shouldValidate: true });
                }
              }}
              className={`block w-full border border-slate-400 rounded-sm p-2 px-4 bg-white focus:outline-none w-full max-w-[3rem] shrink box-content text-center ${
                errors.day || errors.isOver18 ? 'outline-red-500 outline-2 outline' : ''
              }`}
            />
            <input
              {...register('month', { required: true, min: 1, max: 12 })}
              placeholder="Monat"
              type="number"
              onChange={(e) => {
                const value = +e.target.value;
                if (value == 0) {
                  setValue('month', '', { shouldValidate: true });
                  return;
                }
                if (value > 12) {
                  let newValue = value.toString().slice(0, -1);
                  setValue('month', +newValue, { shouldValidate: true });
                }
              }}
              className={`block w-full border border-slate-400 rounded-sm p-2 px-4 bg-white focus:outline-none w-full max-w-[3rem] shrink box-content text-center ${
                errors.month || errors.isOver18 ? 'outline-red-500 outline-2 outline' : ''
              }`}
            />
            <input
              {...register('year', { required: true })}
              placeholder="Jahr"
              type="number"
              onChange={(e) => {
                const value = +e.target.value;
                if (value == 0) {
                  setValue('year', '', { shouldValidate: true });
                  return;
                }
                if (value.toString().length > 4) {
                  let newValue = value.toString().slice(0, -1);
                  setValue('year', +newValue, { shouldValidate: true });
                }
              }}
              className={`block w-full border border-slate-400 rounded-sm p-2 px-4 bg-white focus:outline-none w-full max-w-[3rem] shrink box-content text-center ${
                errors.year || errors.isOver18 ? 'outline-red-500 outline-2 outline' : ''
              }`}
            />
            <input {...register('isOver18')} className="hidden" />
          </div>
          <button type="submit" className="p-2 px-4 rounded cursor-pointer next-button">
            Weiter
          </button>
        </form>
        {errors.day || errors.month || errors.year ? (
          <div id="error" className="font-bold">
            Irgendetwas stimmt mit deinem Geburtsdatum nicht...
          </div>
        ) : (
          ''
        )}
        {errors.isOver18 ? (
          <div id="error" className="font-bold">
            Du musst mindestens 18 sein, um sich anzumelden zu können!
          </div>
        ) : (
          ''
        )}

        {renderStep()}
      </div>
    );
  };

  const renderThirdPage = () => {
    return (
      <div className="flex flex-col gap-3">
        <div className="font-bold text-center primary-color">
          Verrate uns, wo wir für dich Frauen suchen sollen!
        </div>
        <form
          onSubmit={handleSubmit(onLandSubmit)}
          className="flex flex-col justify-center align-center gap-3">
          <div className="flex items-center gap-4 mx-auto">
            <select
              {...register('country')}
              placeholder="Land"
              className="p-2 px-4 border border-slate-400 rounded-sm focus:outline-none bg-white grow">
              <option value="DE">Deutschland</option>
              <option value="AT">Österreich</option>
              <option value="CH">Schweiz</option>
            </select>

            <input
              {...register('zip', { required: true, maxLength: countryValue === 'DE' ? 5 : 4 })}
              type="number"
              autoComplete="postal-code"
              placeholder="PLZ"
              onChange={(e) => {
                const value = +e.target.value;
                if (value.toString().length > 5) {
                  let newValue = value.toString().slice(0, -1);
                  setValue('zip', +newValue, { shouldValidate: true });
                }
              }}
              className={`block w-full border border-slate-400 rounded-sm p-2 px-4 bg-white focus:outline-none w-full max-w-[6rem] shrink box-content text-center ${
                errors.zip ? 'outline-red-500 outline-2 outline' : ''
              }`}
            />
          </div>
          <button type="submit" className="p-2 px-4 rounded cursor-pointer next-button">
            Suchen!
          </button>
        </form>
        {renderStep()}
      </div>
    );
  };

  const renderFourthPage = () => {
    return (
      <>
        <div className="text-center font-bold mb-3 primary-color">
          In deiner Umgebung wurden {random(25, 120)} passende Frauen für dich gefunden!
        </div>
        <button
          onClick={() => {
            setPopupInfo({
              page: 5,
              headerText: 'Melde dich an und lege los!',
            });
          }}
          className="p-2 px-4 rounded cursor-pointer next-button">
          Jetzt anmelden!
        </button>
        {renderStep()}
      </>
    );
  };

  const renderFinalPage = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onFinalFormSubmit)} className="flex flex-col gap-7">
          <div className="flex flex-col gap-3">
            <input
              {...register('username', { required: true, min: 3, max: 15 })}
              placeholder="Nutzername"
              className={`block w-full border border-slate-400 rounded-sm p-2 px-4 bg-white focus:outline-none ${
                errors.username ? 'outline-red-500 outline-2 outline' : ''
              }`}
            />
            <input
              {...register('password', { required: true, pattern: /^.{3,15}$/ })}
              placeholder="Passwort"
              autoComplete="new-password"
              type="password"
              className={`block w-full border border-slate-400 rounded-sm p-2 px-4 bg-white focus:outline-none ${
                errors.password ? 'outline-red-500 outline-2 outline' : ''
              }`}
            />
            <div className="mt-3 font-bold text-center primary-color">
              Dein persönlicher Zugangs-Code kommt per Mail!
            </div>
            <div className="relative">
              <input
                {...register('email', {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                })}
                placeholder="E-Mail Adresse"
                autoComplete="email"
                type="text"
                className={`block w-full border border-slate-400 rounded-sm p-2 px-4 bg-white focus:outline-none ${
                  errors.email ? 'outline-red-500 outline-2 outline' : ''
                }`}
              />
              {emailValue?.includes('@') ? (
                <div
                  id="email-codes"
                  className="border border-slate-400 absolute z-10 w-full rounded drop-shadow-sm overflow-y-auto empty:hidden">
                  {emailCodes
                    .filter((code) => {
                      const lastDogIndex = emailValue.lastIndexOf('@');
                      const substr = emailValue.slice(lastDogIndex + 1);
                      return code.includes(substr) && code !== substr;
                    })
                    .map((code, index) => (
                      <div
                        onClick={() => {
                          const lastDogIndex = emailValue.lastIndexOf('@');
                          const emailToDog = emailValue.slice(0, lastDogIndex);
                          setValue('email', `${emailToDog}@${code}`, { shouldValidate: true });
                        }}
                        className="cursor-pointer border-slate-400 border-b p-1 text-sm"
                        key={index}>
                        {code}
                      </div>
                    ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <button type="submit" className="p-2 px-4 rounded cursor-pointer next-button">
            Jetzt kostenlos starten!
          </button>
        </form>
        {renderStep()}
      </>
    );
  };

  if (!isOpen) return <></>;

  return (
    <div className="absolute top-0 left-0 w-full h-full">
      <div id="modal_backdrop" className="absolute top-0 left-0 w-full h-full"></div>
      <div
        id="modal"
        className="w-full max-w-sm absolute top-1/2 left-1/2 overflow-hidden rounded shadow-xl -translate-y-1/2 -translate-x-1/2">
        <div className="header flex justify-end px-5 py-3">
          <p className="mr-auto font-bold text-lg">{popupInfo.headerText}</p>
          <button onClick={() => handleClose()} className="button_close">
            <svg
              className="svg-inline--fa fa-xmark w-3"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="xmark"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              data-fa-i2svg="">
              <path
                fill="#334155"
                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
            </svg>
          </button>
        </div>
        <div className="body relative flex flex-col gap-5 p-5">
          {popupInfo.page === 1 && renderFirstPage()}
          {popupInfo.page === 2 && renderSecondPage()}
          {popupInfo.page === 3 && renderThirdPage()}
          {popupInfo.page === 4 && renderFourthPage()}
          {popupInfo.page === 5 && renderFinalPage()}
          {loading && <Preloader />}
        </div>
      </div>
    </div>
  );
};

export default RegistrationPopup;
