import React from 'react';

const BaseContainer = ({ children }) => {
  return (
    <div className="m-auto max-w-[970px] px-4">
      {children}
    </div>
  );
};

export default BaseContainer;