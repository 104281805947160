import React, { useState } from 'react';
import BaseContainer from '../BaseContainer/BaseContainer';
import Filters from '../Filters/Filters';
import Profiles from '../Profiles/Profiles';

const Main = () => {
  const [filters, setFilters] = useState({
    type: '',
    age: '',
    hair: '',
  });

  const handleChangeFilter = (filter) => {
    setFilters((prevFilter) => {
      prevFilter[filter.key] = filter.value;
      return {
        ...prevFilter,
      };
    });
  };

  return (
    <div className="w-full">
      <BaseContainer>
        <div className="flex py-6 px-2 gap-3">
          <Filters handleChangeFilter={handleChangeFilter} />
          <Profiles filters={filters} isFiltersEnabled={true} />
        </div>
      </BaseContainer>
    </div>
  );
};

export default Main;
