import React from 'react';

const Preloader = () => {
  return (
    <div>
      <div
        id="loader"
        className="absolute top-0 left-0 flex w-full h-full items-center justify-center">
        <div className="backdrop absolute top-0 left-0 w-full h-full"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="150px"
          height="150px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          className="spinner relative"
          style={{ margin: 'auto', shapeRendering: 'auto' }}>
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="8"
            strokeDasharray="164.2169140625 92.37201416015625"
            d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
            strokeLinecap="round"
            style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}>
            <animate
              attributeName="stroke-dashoffset"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;1"
              values="0;256.58892822265625"></animate>
          </path>
        </svg>
      </div>
    </div>
  );
};

export default Preloader;
