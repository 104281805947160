import React from 'react';

const Footer = () => {
  return (
    <div>
      <div id="footer" className="flex items-center justify-center gap-2 p-2">
        <a
          href="https://flirtrakete.net/impressum.php"
          target="_blank"
          className="text-sm hover:underline"
          data-noop=""
          data-popup="">
          Impressum
        </a>
        <div className="separator"></div>
        <a
          href="https://flirtrakete.net/datenschutz.php"
          target="_blank"
          className="text-sm hover:underline"
          data-noop=""
          data-popup="">
          Datenschutz
        </a>
        <div className="separator"></div>
        <a
          href="https://flirtrakete.net/agb.php"
          target="_blank"
          className="text-sm hover:underline"
          data-noop=""
          data-popup="">
          AGB
        </a>
        <div className="separator"></div>
        <a href="#" className="text-sm hover:underline">
          Affiliates
        </a>
      </div>
    </div>
  );
};

export default Footer;
