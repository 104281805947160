import React from 'react';
import { useState } from 'react';

const Filters = ({ handleChangeFilter }) => {
  const [typeCategory, setTypeCategory] = useState('');
  const [ageCategory, setAgeCategory] = useState('');
  const [hairCategory, setHairCategory] = useState('');

  const typeCategories = ['Teen', 'Reife Frauen', 'Mollig', 'Single'];
  const ageCategories = ['18+', '20+', '30+', '40+', '50+', '60+'];
  const hairCategories = ['Blondine', 'Brünette', 'Rothaarige'];

  return (
    <div id="filters" className="hidden sm:flex flex-col gap-3 max-w-[230px]">
      <div className="p-3 rounded-sm drop-shadow-lg container">
        <h4 className="font-bold text-lg primary-color">Alle Kategorien</h4>
        <div className="flex flex-row flex-wrap gap-2 mt-2 px-2">
          {typeCategories.map((category, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  if (category === typeCategory) {
                    setTypeCategory('');
                    handleChangeFilter({
                      key: 'type',
                      value: '',
                    });
                  } else {
                    setTypeCategory(category);
                    handleChangeFilter({
                      key: 'type',
                      value: category,
                    });
                  }
                }}
                className={`primary-color px-3 py-1 btn-grey-bg rounded cursor-pointer btn-grey-hover-bg ${
                  typeCategory === category ? 'active-category' : ''
                }`}>
                {category}
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-3 rounded-sm drop-shadow-lg container">
        <h4 className="font-bold text-lg primary-color">Kategorien</h4>
        <div className="p-2">
          <h5 className="font-bold primary-color">Milf</h5>
          <div className="px-5">
            {ageCategories.map((category, index) => {
              return (
                <div
                  onClick={() => {
                    if (category === ageCategory) {
                      setAgeCategory('');
                      handleChangeFilter({
                        key: 'age',
                        value: '',
                      });
                    } else {
                      setAgeCategory(category);
                      handleChangeFilter({
                        key: 'age',
                        value: category,
                      });
                    }
                  }}
                  key={index}
                  className={`hover:underline cursor-pointer primary-color ${
                    ageCategory === category ? 'underline' : ''
                  }`}>
                  {category}
                </div>
              );
            })}
          </div>
        </div>
        <div className="p-2">
          <h5 className="font-bold primary-color">Aussehen</h5>
          <div className="px-5">
            {hairCategories.map((category, index) => {
              return (
                <div
                  onClick={() => {
                    if (category === hairCategory) {
                      setHairCategory('');
                      handleChangeFilter({
                        key: 'hair',
                        value: '',
                      });
                    } else {
                      setHairCategory(category);
                      handleChangeFilter({
                        key: 'hair',
                        value: category,
                      });
                    }
                  }}
                  key={index}
                  className={`hover:underline cursor-pointer primary-color ${
                    hairCategory === category ? 'underline' : ''
                  }`}>
                  {category}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
