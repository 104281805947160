import React, { memo } from 'react';
import { useEffect, useState } from 'react';
import arrayShuffle from 'array-shuffle';
import ProfileCard from '../../components/ProfileCard/ProfileCard';

const Profiles = ({ filters, isFiltersEnabled }) => {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchProfiles = async () => {
      const response = await fetch('/profiles.json');
      const data = await response.json();
      setProfiles(data);
    };
    fetchProfiles();
  }, []);

  const shuffleProfiles = (profiles) => {
    const newProfiles = [...profiles];
    return arrayShuffle(newProfiles);
  };

  const getHighlightedProfiles = (profiles) => {
    return profiles.slice(0, 3);
  };

  const getRegularProfiles = (profiles) => {
    return profiles.slice(3);
  };

  const filterProfiles = (profiles) => {
    return profiles.filter((profile) => {
      const filterValues = [filters.type, filters.age, filters.hair];
      let profileIncludes = true;
      for (const value of filterValues) {
        if (value && !profile.category.includes(value)) {
          profileIncludes = false;
        }
      }
      return profileIncludes;
    });
  };

  let filteredProfiles = shuffleProfiles(profiles);
  if (isFiltersEnabled) {
    filteredProfiles = filterProfiles(filteredProfiles);
  }
  filteredProfiles = filteredProfiles.slice(0, 15);

  return (
    <div className="flex flex-col gap-7 w-full">
      <div id="highlight" className="flex flex-col gap-3 rounded p-5">
        <h2 className="font-bold text-xl px-2 primary-color">Single Frauen in deiner Nähe</h2>
        <div
          id="highlight-grid"
          className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-5 auto-rows-max">
          {filteredProfiles &&
            getHighlightedProfiles(filteredProfiles).map((profile, index) => {
              return (
                <ProfileCard
                  key={index}
                  name={profile.name}
                  age={profile.age}
                  imageUrl={profile.pic}
                  intro={profile.intro}
                  id={profile.campaign}
                />
              );
            })}
        </div>
      </div>

      <div id="regular-grid" className="grid grid-cols-2 md:grid-cols-3 gap-5 auto-rows-max">
        {filteredProfiles &&
          getRegularProfiles(filteredProfiles).map((profile, index) => {
            return (
              <ProfileCard
                key={index}
                name={profile.name}
                age={profile.age}
                imageUrl={profile.pic}
                intro={profile.intro}
                id={profile.campaign}
              />
            );
          })}
      </div>
    </div>
  );
};

export default memo(Profiles);
