import React from 'react';
import BaseContainer from '../BaseContainer/BaseContainer';
import logoImg from '../../assets/logo.png';

const Logo = () => {
  return (
    <div className='w-full px-5 py-2'>
      <BaseContainer>
        <div className="py-2">
          <img className="max-w-[300px]" src={logoImg} />
        </div>
      </BaseContainer>
    </div>
  );
};

export default Logo;