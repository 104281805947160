import axios from 'axios';
import md5 from 'md5';
const token = 'Gn8F3AlLq19z';

function makeHash(body) {
  const dataQuery = new URLSearchParams(
    Object.entries(body).map(([key, value]) => [key, `${value}`]),
  );
  return md5(dataQuery + token);
}

export const registerApi = async (body) => {
  const hash = makeHash(body);
  body.hash = hash;
  try {
    const response = await fetch('https://flirtrakete.net/API/api.adultstats_net.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return response;
  } catch (error) {
    return error;
  }
};
