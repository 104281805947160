import React from 'react';
import BaseContainer from '../BaseContainer/BaseContainer';

const Header = () => {
  return (
    <div id="header" className="w-full shadow-sm">
      <BaseContainer>
        <div className="flex flex-col lg:flex-row m-auto max-w-[970px] gap-3 lg:gap-6 justify-between items-center p-3 pb-2">
          <div className="flex flex-col xs:flex-row gap-3 items-end">
            <div className="flex gap-3 max-md:flex-col">
              <div className="flex rounded input overflow-hidden">
                <div className="input flex grow items-center p-2 px-4 text-sm bg-white">
                  <p>Wen suchen Sie?</p>
                </div>
                <div className="button font-medium p-2 px-4 cursor-pointer btn-grey-bg primary-color border-solid border-l">
                  Alle Kategorien
                </div>
              </div>
              <div className="flex rounded input overflow-hidden">
                <div className="input flex grow items-center p-2 px-4 text-sm bg-white">
                  <p>PLZ oder Ort</p>
                </div>
                <div className="button font-medium p-2 px-4 cursor-pointer btn-grey-bg primary-color border-solid border-l">
                  Ganzer Ort
                </div>
              </div>
            </div>
            <div className="flex items-center search rounded cursor-pointer p-2 px-4 gap-2 h-fit">
              <svg
                className="svg-inline--fa fa-magnifying-glass w-4"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="magnifying-glass"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg="">
                <path
                  fill="white"
                  d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"></path>
              </svg>
              <p className="text-white">Suchen</p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <a href="#" className="button hover:underline flex opacity-80 items-center gap-1">
              <svg
                className="svg-inline--fa fa-message w-4"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="message"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg="">
                <path
                  fill="currentColor"
                  d="M511.1 63.1v287.1c0 35.25-28.75 63.1-64 63.1h-144l-124.9 93.68c-7.875 5.75-19.12 .0497-19.12-9.7v-83.98h-96c-35.25 0-64-28.75-64-63.1V63.1c0-35.25 28.75-63.1 64-63.1h384C483.2 0 511.1 28.75 511.1 63.1z"></path>
              </svg>
              Nachrichten(3)
            </a>
            <a href="#" className="button hover:underline flex opacity-80 items-center gap-1">
              <svg
                className="svg-inline--fa fa-user w-4"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="user"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg="">
                <path
                  fill="currentColor"
                  d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"></path>
              </svg>
              Profil
            </a>
          </div>
        </div>
      </BaseContainer>
    </div>
  );
};

export default Header;
