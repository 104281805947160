import React from 'react';

const ProfileCard = ({ imageUrl, name, age, intro, id }) => {
  const getTagValue = () => {
    if (age >= 18 && age < 20) {
      return '18+';
    } else if (age >= 20 && age < 30) {
      return '20+';
    } else if (age >= 30 && age < 40) {
      return '30+';
    } else if (age >= 40 && age < 50) {
      return '40+';
    } else if (age >= 50 && age < 60) {
      return '50+';
    } else {
      return '60+';
    }
  };

  return (
    <div
      onClick={() => {
        window.localStorage.setItem('campaign', id);
        window.localStorage.setItem('current-profile', JSON.stringify({ name, age }));
      }}
      className="card relative flex min-h-[325px] flex-col gap-1 p-2 cursor-pointer transition-all duration-300 ease-in-out scale100 drop-shadow-none hover:scale-105 hover:drop-shadow-lg"
      tabIndex="0">
      <div className="relative w-full aspect-square shrink-0 overflow-hidden">
        <div
          style={{
            backgroundPositionY: '0%',
            backgroundImage: `url(${imageUrl})`,
          }}
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full bg-no-repeat bg-center bg-cover transition-transform duration-300 ease-in-out scale-100"></div>
      </div>
      <h3 className="font-bold primary-color">
        {name}, {age}
      </h3>
      <div className="info text-sm italic primary-color">{intro}</div>
      <div className="location flex justify-end items-center gap-2 mt-auto text-right text-xs font-bold pl-3">
        <svg
          className="svg-inline--fa fa-location-dot w-2"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="location-dot"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg="">
          <path
            fill="currentColor"
            d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"></path>
        </svg>{' '}
        <div className="overflow-hidden whitespace-nowrap text-ellipsis">in deiner Nähe</div>
      </div>
      <div className="tag absolute top-10 right-0 px-1.5 py-0.5 font-medium text-sm rounded-l-sm">
        {getTagValue()}
      </div>
    </div>
  );
};

export default ProfileCard;
