import Logo from './layout/Logo/Logo';
import Header from './layout/Header/Header';
import Main from './layout/Main/Main';
import RegistrationPopup from './layout/RegistrationPopup/RegistrationPopup';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Footer from './layout/Footer/Footer';
const OPEN_POPUP_INTERVAL = 15 * 1000;
const DEFAULT_CAMPAIGN = '17';

const App = () => {
  const [isRegistrationPopupOpen, setIsRegistrationPopupOpen] = useState(false);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const pageClickListener = (event) => {
      if (
        !isRegistrationPopupOpen &&
        !event.target.closest('.button_close') &&
        !event.target.closest('#filters')
      ) {
        setIsRegistrationPopupOpen(true);
      }
    };
    const popupOpenInterval = () => {
      if (!isRegistrationPopupOpen) {
        setIsRegistrationPopupOpen(true);
      }
    };
    const openPopupInterval = setInterval(popupOpenInterval, OPEN_POPUP_INTERVAL);
    document.body.addEventListener('click', pageClickListener);
    return () => {
      document.body.removeEventListener('click', pageClickListener);
      clearInterval(openPopupInterval);
    };
  }, [isRegistrationPopupOpen]);

  useEffect(() => {
    const campaign = searchParams.get('campaign');
    if (campaign) {
      window.localStorage.setItem('campaign', campaign);
    } else {
      window.localStorage.setItem('campaign', DEFAULT_CAMPAIGN);
    }
  }, [searchParams]);

  useEffect(() => {
    window.localStorage.removeItem('current-profile');
  }, []);

  const handleCloseRegistrationPopup = () => {
    setIsRegistrationPopupOpen(false);
    window.localStorage.removeItem('current-profile');
  };

  return (
    <div className="w-full min-h-screen">
      <Logo />
      <Header />
      <Main />
      <Footer />
      <RegistrationPopup
        isOpen={isRegistrationPopupOpen}
        handleClose={handleCloseRegistrationPopup}
      />
    </div>
  );
};

export default App;
